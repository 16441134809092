"use client";

import { CosmosLinkCard } from "@cosmos/web/react";
import { WorbRailItem } from "../../../__generated__/crepo-types";
import createTypenameTypeguard from "../../../helpers/createTypenameTypeguard";
import { notNullish } from "../../../helpers/notNullish";
import { getRbImageUrl } from "../../../helpers/images/getRbImageUrl";
import { useLocaleContext } from "../../../helpers/LocaleContext";

const checkIsImage = createTypenameTypeguard("Image");
const checkIsPreviewClip = createTypenameTypeguard("PreviewClip");
const checkIsMediabaseDeliveryAgentClips = createTypenameTypeguard(
  "MediabaseDeliveryAgentClips",
);

export const WorbRailCard = ({
  card,
  loading,
}: {
  card: WorbRailItem;
  loading: "eager" | "lazy";
}) => {
  const video = card.featuredMedia
    ?.filter(notNullish)
    .filter(checkIsPreviewClip)
    .map((previewClip) => previewClip.clipEssence)
    .filter(notNullish)
    .find(checkIsMediabaseDeliveryAgentClips)
    ?.renditions?.filter(notNullish)
    .find((rendition) => rendition.ratioTextual === "9x16");

  const locale = useLocaleContext();

  const image = card.featuredMedia?.filter(notNullish).find(checkIsImage);
  const imageUrl =
    (image?.imageEssence?.__typename === "CloudinaryImage" &&
      image.imageEssence?.imageURL &&
      getRbImageUrl(
        image.imageEssence?.imageURL,
        {
          width: 770,
          height: 578,
          g: "auto",
        },
        locale,
      )) ||
    image?.imageSrc;

  const logoImage = card.logo?.filter(notNullish)[0];
  const logoImageUrl =
    logoImage?.imageEssence?.__typename === "CloudinaryImage"
      ? logoImage.imageEssence?.imageURL.replace(
          "{op}",
          "w_309,c_fill,f_auto,e_trim:10:transparent",
        )
      : logoImage?.imageSrc?.replace("w_150,h_100", `w_309`) ?? undefined;

  const disciplines =
    card.disciplines
      ?.map((discipline) => discipline?.title?.text)
      .filter(notNullish)
      .join(", ") || undefined;

  return (
    <CosmosLinkCard
      imageLoading={loading}
      id={card.id}
      href={card.url?.href ?? "#"}
      target="_blank"
      rel="noopener noreferrer"
      image={imageUrl ?? ""}
      video={video?.clipURL ?? undefined}
      tag={disciplines}
      logo={logoImageUrl}
      headline={card.title?.text ?? ""}
      text={card.subHeading?.text ?? undefined}
    />
  );
};
